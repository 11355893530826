import { useState, useEffect } from 'react';
import className from 'classnames/bind';
import styles from './TabsMap.module.scss';
import ImageSlider from '~/Components/Controls/ImageSilde';
import VideoPlayer from '~/Components/Controls/VideoPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ResultStorage from '~/Function/ResultStorage';
import MediaAPI from '~/Api/Media';

const css = className.bind(styles);
function TabsMap({ id, feature, openInfor, coordinates, xy }) {
    const [activeTab, setActiveTab] = useState('thongtin');
    const [isOpenUpdate, setIsOpenUpdate] = useState(false);
    const [video, setVideo] = useState([]);
    const [image, setImage] = useState([]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        const media = new MediaAPI();
        const formData = new FormData();
        formData.append('Position',coordinates.map(coord => coord.join(',')).join(','));
        if(tab === 'hinhanh'){
            media.getPathMediaImageByPosition(formData).then((response) => {
                if(response.status === 200){
                    const stringArray = response.data.map(jsonString => {
                        const cleanedString = jsonString.replace('{ Path = ', '').replace(' }', '');
                        return cleanedString;
                    });
                    setImage(stringArray);
                }
            });
        } else if(tab === 'video'){
            media.getPathMediaVideoByPosition(formData).then((response) => {
                if(response.status === 200){
                    const stringArray = response.data.map(jsonString => {
                        const cleanedString = jsonString.replace('{ Path = ', '').replace(' }', '');
                        return cleanedString;
                    });
                    setVideo(stringArray);
                }
            });
        }
    };
    
    const fieldNames = Object.keys(feature);
    const handleUploadInformation = () => {
        openInfor(true);
    };

    useEffect(() => {
        let concurrencyStamp = localStorage.getItem('_ConcurrencyStamp');
        const result = new ResultStorage();
        if(result.getConcurrencyStampAdmin() === concurrencyStamp || result.getConcurrencyStampOperator() === concurrencyStamp){
            setIsOpenUpdate(true);
        } else {
            setIsOpenUpdate(false);
        }
    },[feature]);

    return (
        <div className={css('tabs')} >
            <div className={css('tabs-header')} >
                <div className={css('tab-item', `${activeTab === 'thongtin' ? 'active' : ''}`)} onClick={() => handleTabClick('thongtin')}>
                    Thông tin
                </div>
                {id===52&&
                <div className={css(`tab-item`, `${activeTab === 'thongso' ? 'active' : ''}`)} onClick={() => handleTabClick('thongso')}>
                    Thông số
                </div>}
                <div className={css(`tab-item`, `${activeTab === 'hinhanh' ? 'active' : ''}`)} onClick={() => handleTabClick('hinhanh')}>
                    Hình ảnh
                </div>
                <div className={css(`tab-item`, `${activeTab === 'video' ? 'active' : ''}`)} onClick={() => handleTabClick('video')}>
                    Video
                </div>
            </div>
            <div className={css("tabs-content")}>
                {activeTab === 'thongtin' && (
                    fieldNames?(
                        <div className={css("info")}>
                            {fieldNames!==null && fieldNames!=="" && fieldNames.map(fieldName => (
                                feature[fieldName]!==null && feature[fieldName]!=="" && (
                                    <div className={css("item-info")}>
                                        <div className={css("label")}>{fieldName}:</div> 
                                        <div className={css("txt")}>{feature[fieldName]}</div>
                                    </div>
                                )
                            ))}
                            {id===52|id===51&&(
                                <>
                                    <div className={css("item-info")}>
                                        <div className={css("label")}>X:</div> 
                                        <div className={css("txt")}>{xy.lat}</div>
                                    </div>
                                    <div className={css("item-info")}>
                                        <div className={css("label")}>Y:</div> 
                                        <div className={css("txt")}>{xy.lng}</div>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={css('tab-upload')}>
                            <p className={css("message")}>Chưa cập nhật thông tin.</p>
                            {isOpenUpdate && (
                                <div className={css("btn-upload")} onClick={handleUploadInformation}>
                                    <div className={css('btn')}>
                                        <FontAwesomeIcon style={{display: 'inline-block', float: 'left', width: '20%', paddingLeft:'0.5rem'}} size='1x' icon={faEdit} />
                                        <p style={{display: 'inline-block', float: 'left', width: '80%', fontSize: '0.9rem'}}>Cập nhật thông tin</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                )}
                {activeTab === 'thongso' && (
                    fieldNames.map(fieldName => (
                        <div>
                            {/* Khu vực thượng lưu */}
                            {/* {feature[fieldName]===6&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th rowspan="3">S</th>
                                            <th rowspan="3">Vị trí</th>
                                            <th colspan="10">Thông số chỉnh trị</th>
                                        </tr>
                                        <tr>
                                            <th>Lưu lượng tạo lòng</th>
                                            <th>Mực nước tạo lòng</th>
                                            <th rowspan="2">Lưu lượng thiết kế tại đầu cù lao (m<sup>3</sup>/s)</th>
                                            <th>Mực nước thiết kế</th>
                                            <th>B thượng lưu/hạ lưu</th>
                                            <th>Vận tốc trung bình</th>
                                            <th rowspan="2">Bán kính cong R (m)</th>
                                            <th rowspan="2">Chỉ số ổn định dọc (n<sub>n</sub>) và ngang (n<sub>n</sub>)</th>
                                            <th rowspan="2">Cao độ nạo vét (m)</th>
                                            <th>Tỷ lệ phân lưu nút đầu cù lao Q<sub>T</sub>/Q<sub>P</sub></th>
                                        </tr>
                                        <tr>
                                            <th>(m<sup>3</sup>/s)</th>
                                            <th>(m)</th>
                                            <th>(m)</th>
                                            <th>(m)</th>
                                            <th>(m/s)</th>
                                            <th>(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Tân Châu - Hồng Ngự</td>
                                            <td>22000</td>
                                            <td>3,4</td>
                                            <td>22703</td>
                                            <td>3,7</td>
                                            <td>2000/1100</td>
                                            <td>0,5-1,0</td>
                                            <td>4300</td>
                                            <td>0,39<br/>1,68</td>
                                            <td>(-12)<br/>(-17)</td>
                                            <td>39/61</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="3">2</td>
                                            <td rowspan="3">Cù Lao Châu Ma</td>
                                            <td rowspan="3">18375</td>
                                            <td rowspan="3">2,0</td>
                                            <td rowspan="3">22675</td>
                                            <td rowspan="3">2,4</td>
                                            <td rowspan="3">2800/1400</td>
                                            <td rowspan="3">0,4-0,7</td>
                                            <td rowspan="3">26000</td>
                                            <td>0,28</td>
                                            <td>(-12),(-17)</td>
                                            <td>Max: 71/34</td>
                                        </tr>
                                        <tr>
                                            <td>2,73</td>
                                            <td>(-19)</td>
                                            <td>Lũ: 68/32</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Kiệt: 67/33</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">3</td>
                                            <td rowspan="2">Bình Thành</td>
                                            <td rowspan="2">10125</td>
                                            <td rowspan="2">2,7</td>
                                            <td rowspan="2">16228</td>
                                            <td rowspan="2">2,9</td>
                                            <td>1100</td>
                                            <td rowspan="2">0,3-1,8</td>
                                            <td rowspan="2">4100</td>
                                            <td>0,28</td>
                                            <td rowspan="2">-22</td>
                                            <td>Cù lao Giêng: 94/6</td>
                                        </tr>
                                        <tr>
                                            <td>800</td>
                                            <td>1,11</td>
                                            <td>Cầu Cao Lãnh: 37/63</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">4</td>
                                            <td rowspan="2">Châu Đốc</td>
                                            <td rowspan="2">4237</td>
                                            <td rowspan="2">2,2</td>
                                            <td rowspan="2">5452</td>
                                            <td rowspan="2">2,5</td>
                                            <td>800</td>
                                            <td rowspan="2">0,3-0,5</td>
                                            <td rowspan="2">4200</td>
                                            <td>0,27</td>
                                            <td rowspan="2">-15</td>
                                            <td rowspan="2">-</td>
                                        </tr>
                                        <tr>
                                            <td>560</td>
                                            <td>1,60</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">5</td>
                                            <td rowspan="2">QL91</td>
                                            <td rowspan="2">5483</td>
                                            <td rowspan="2">3,3</td>
                                            <td rowspan="2">5640</td>
                                            <td rowspan="2">3,6</td>
                                            <td>600</td>
                                            <td rowspan="2">0,4-2,0</td>
                                            <td rowspan="2">2800</td>
                                            <td rowspan="2"></td>
                                            <td rowspan="2">-17</td>
                                            <td rowspan="2">-</td>
                                        </tr>
                                        <tr>
                                            <td>300</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="3">6</td>
                                            <td rowspan="3">Vàm Nao</td>
                                            <td rowspan="3">6267</td>
                                            <td rowspan="3">1,7</td>
                                            <td rowspan="3">8285</td>
                                            <td rowspan="3">2,1</td>
                                            <td rowspan="3">550/440</td>
                                            <td rowspan="3">0,5-0,8</td>
                                            <td rowspan="3">850</td>
                                            <td rowspan="3">0,34<br/>0,92</td>
                                            <td rowspan="3">-15</td>
                                            <td>Max: 39/60</td>
                                        </tr>
                                        <tr>
                                            <td>Lũ: 34/61</td>
                                        </tr>
                                        <tr>
                                            <td>Kiệt: 29/71</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">7</td>
                                            <td rowspan="2">Long Xuyên</td>
                                            <td rowspan="2">11483</td>
                                            <td rowspan="2">2,9</td>
                                            <td rowspan="2">15000</td>
                                            <td rowspan="2">3,0</td>
                                            <td>800</td>
                                            <td rowspan="2">0,4-1,0</td>
                                            <td rowspan="2">4300</td>
                                            <td>0,29</td>
                                            <td rowspan="2">-17</td>
                                            <td rowspan="2">37/63</td>
                                        </tr>
                                        <tr>
                                            <td>600</td>
                                            <td>1,07</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )} */}
                            {/* Khu vực hạ lưu */}
                            {feature[fieldName]===1|feature[fieldName]===2&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th rowspan="3">Vị trí</th>
                                            <th colspan="9">Thông số chỉnh trị</th>
                                        </tr>
                                        <tr>
                                            <th>Lưu lượng tạo lòng</th>
                                            <th>Mực nước tạo lòng</th>
                                            <th rowspan="2">Lưu lượng thiết kế tại đầu cù lao (m<sup>3</sup>/s)</th>
                                            <th>Mực nước thiết kế</th>
                                            <th>B thượng lưu/hạ lưu</th>
                                            <th>Vận tốc trung bình</th>
                                            <th rowspan="2">Bán kính cong R (m)</th>
                                            <th rowspan="2">Cao độ nạo vét (m)</th>
                                            <th>Tỷ lệ phân lưu nút đầu cù lao Q<sub>T</sub>/Q<sub>P</sub></th>
                                        </tr>
                                        <tr>
                                            <th>(m<sup>3</sup>/s)</th>
                                            <th>(m)</th>
                                            <th>(m)</th>
                                            <th>(m)</th>
                                            <th>(m/s)</th>
                                            <th>(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td rowspan="2">Bến phà Tân Phú (H.Châu Thành, Thành Bến Tre)</td>
                                            <td rowspan="2">4073</td>
                                            <td rowspan="2">0,3</td>
                                            <td rowspan="2">7568</td>
                                            <td rowspan="2">1,1</td>
                                            <td >1800</td>
                                            <td rowspan="2">0,2-1,0</td>
                                            <td rowspan="2">2200</td>
                                            <td rowspan="2">-</td>
                                            <td rowspan="2">45/55</td>
                                        </tr>
                                        <tr>
                                            <td>1000</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">Mỹ Tho</td>
                                            <td rowspan="2">4073</td>
                                            <td rowspan="2">2,1</td>
                                            <td rowspan="2">5300</td>
                                            <td rowspan="2">2,1</td>
                                            <td >1785</td>
                                            <td rowspan="2">0,2-0,6</td>
                                            <td rowspan="2">5328</td>
                                            <td rowspan="2">-</td>
                                            <td rowspan="2">Cù lao Thới Sơn: 82/18</td>
                                        </tr>
                                        <tr>
                                            <td>1568</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">Cù lao Tam Hiệp (Bến Tre)</td>
                                            <td rowspan="2">3995</td>
                                            <td rowspan="2">-0,1</td>
                                            <td rowspan="2">10763</td>
                                            <td rowspan="2">0,4</td>
                                            <td >2000</td>
                                            <td rowspan="2">0,3-0,5</td>
                                            <td rowspan="2">5000</td>
                                            <td rowspan="2">-</td>
                                            <td rowspan="2">70/30</td>
                                        </tr>
                                        <tr>
                                            <td>1300</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">Cầu Hàm Luông</td>
                                            <td rowspan="2">2580</td>
                                            <td rowspan="2">0,2</td>
                                            <td rowspan="2">6784</td>
                                            <td rowspan="2">0,7</td>
                                            <td >645</td>
                                            <td rowspan="2">0,3-0,8</td>
                                            <td rowspan="2">2700</td>
                                            <td rowspan="2">-</td>
                                            <td rowspan="2">-</td>
                                        </tr>
                                        <tr>
                                            <td>710</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {/* Khu vực trung lưu */}
                            {feature[fieldName]===3|feature[fieldName]===4|feature[fieldName]===5&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th rowspan="3">Vị trí</th>
                                            <th colspan="9">Thông số chỉnh trị</th>
                                        </tr>
                                        <tr>
                                            <th>Lưu lượng tạo lòng</th>
                                            <th>Mực nước tạo lòng</th>
                                            <th rowspan="2">Lưu lượng thiết kế tại đầu cù lao (m<sup>3</sup>/s)</th>
                                            <th>Mực nước thiết kế</th>
                                            <th>B thượng lưu/hạ lưu</th>
                                            <th>Vận tốc trung bình</th>
                                            <th rowspan="2">Bán kính cong R (m)</th>
                                            <th rowspan="2">Cao độ nạo vét (m)</th>
                                            <th>Tỷ lệ phân lưu nút đầu cù lao Q<sub>T</sub>/Q<sub>P</sub></th>
                                        </tr>
                                        <tr>
                                            <th>(m<sup>3</sup>/s)</th>
                                            <th>(m)</th>
                                            <th>(m)</th>
                                            <th>(m)</th>
                                            <th>(m/s)</th>
                                            <th>(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {feature[fieldName]===3 && (
                                            <>
                                                <tr>
                                                    <td rowspan="2">Cần Thơ</td>
                                                    <td rowspan="2">11475</td>
                                                    <td rowspan="2">0,3</td>
                                                    <td rowspan="2">20158</td>
                                                    <td rowspan="2">0,5</td>
                                                    <td >1700</td>
                                                    <td rowspan="2">0,4-1,3</td>
                                                    <td rowspan="2">8500</td>
                                                    <td rowspan="2">-</td>
                                                    <td rowspan="2">-</td>
                                                </tr>
                                                <tr>
                                                    <td>1600</td>
                                                </tr>
                                            </>
                                        )}
                                        {feature[fieldName]===4 | feature[fieldName]===5 && (
                                            <>
                                                <tr>
                                                    <td rowspan="2">Mỹ Thuận</td>
                                                    <td rowspan="2">13500</td>
                                                    <td rowspan="2">0,5</td>
                                                    <td rowspan="2">23922</td>
                                                    <td rowspan="2">1,0</td>
                                                    <td >1900</td>
                                                    <td rowspan="2">0,3-1,1</td>
                                                    <td rowspan="2">4300</td>
                                                    <td rowspan="2">-</td>
                                                    <td rowspan="2">30/70</td>
                                                </tr>
                                                <tr>
                                                    <td>1600</td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            )}
                            {/* Sông Hậu - Châu Đốc */}
                            {feature[fieldName]===12&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th colspan="4">SÔNG HẬU - CHÂU ĐỐC</th>
                                        </tr>
                                        <tr>
                                            <th>STT</th>
                                            <th>Thông số chỉnh trị</th>
                                            <th>Đơn vị</th>
                                            <th>Giá trị</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Lưu lượng tạo lòng</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>4236.7</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Mực nước tạo lòng</td>
                                            <td>m</td>
                                            <td>2.2</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Lưu lượng thiết kế tại đầu cù lao</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>5452.27</td>
                                        </tr>
                                        <tr>
                                            <th>4</th>
                                            <th>Mực nước thiết kế</th>
                                            <th>m</th>
                                            <th>2.5</th>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Chiều rộng thượng lưu/hạ lưu</td>
                                            <td>m</td>
                                            <td>880/560</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Vận tốc trung bình</td>
                                            <td>m/s</td>
                                            <td>0,3-0,5</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Bán kính cong R</td>
                                            <td>m</td>
                                            <td>4200</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Cao độ nạo nét</td>
                                            <td>m</td>
                                            <td>-15</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao</td>
                                            <td>%</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Chiều sâu lòng sông ứng với lưu lượng tạo lòng (h<sub>tl</sub>)</td>
                                            <td>m</td>
                                            <td>15.5</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Đường kính hạt trong khu vực nghiên cứu (d<sub>h</sub>)</td>
                                            <td>mm</td>
                                            <td>0.1</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Độ dốc mặt nước (i)</td>
                                            <td></td>
                                            <td>0.000024</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td>Chỉ số ổn định theo chiều dọc tuyến chỉnh trị (n<sub>h</sub>)</td>
                                            <td></td>
                                            <td>0.27</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                            <td>Chỉ số ổn định theo chiều ngang tuyến chỉnh trị (n<sub>n</sub>)</td>
                                            <td></td>
                                            <td>1.6</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {/* Sông Hậu - Vàm Nao */}
                            {feature[fieldName]===14|feature[fieldName]===6&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th colspan="4">SÔNG HẬU - VÀM NAO</th>
                                        </tr>
                                        <tr>
                                            <th>STT</th>
                                            <th>Thông số chỉnh trị</th>
                                            <th>Đơn vị</th>
                                            <th>Giá trị</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Lưu lượng tạo lòng</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>6267</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Mực nước tạo lòng</td>
                                            <td>m</td>
                                            <td>1.67</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Lưu lượng thiết kế tại đầu cù lao</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>8285</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Mực nước thiết kế</td>
                                            <td>m</td>
                                            <td>2.07</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Chiều rộng thượng lưu/hạ lưu</td>
                                            <td>m</td>
                                            <td>550/440</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Vận tốc trung bình</td>
                                            <td>m/s</td>
                                            <td>0,5-0,8</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Bán kính cong R</td>
                                            <td>m</td>
                                            <td>850</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Cao độ nạo nét</td>
                                            <td>m</td>
                                            <td>-15</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao (QL91/VN) - Lớn nhất</td>
                                            <td>%</td>
                                            <td>39/60</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao (QL91/VN) - Mùa lũ</td>
                                            <td>%</td>
                                            <td>34/61</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao (QL91/VN) - Mùa kiệt</td>
                                            <td>%</td>
                                            <td>29/71</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Chiều sâu lòng sông ứng với lưu lượng tạo lòng (h<sub>tl</sub>)</td>
                                            <td>m</td>
                                            <td>17.67</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td>Đường kính hạt trong khu vực nghiên cứu (d<sub>h</sub>)</td>
                                            <td>mm</td>
                                            <td>0.24</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                            <td>Độ dốc mặt nước (i)</td>
                                            <td></td>
                                            <td>0.00004</td>
                                        </tr>
                                        <tr>
                                            <td>15</td>
                                            <td>Chỉ số ổn định theo chiều dọc tuyến chỉnh trị (n<sub>h</sub>)</td>
                                            <td></td>
                                            <td>0.34</td>
                                        </tr>
                                        <tr>
                                            <td>16</td>
                                            <td>Chỉ số ổn định theo chiều ngang tuyến chỉnh trị (n<sub>n</sub>)</td>
                                            <td></td>
                                            <td>0.92</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {/* Sông Hậu - Long Xuyên */}
                            {feature[fieldName]===13&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th colspan="4">SÔNG HẬU - LONG XUYÊN</th>
                                        </tr>
                                        <tr>
                                            <th>STT</th>
                                            <th>Thông số chỉnh trị</th>
                                            <th>Đơn vị</th>
                                            <th>Giá trị</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Lưu lượng tạo lòng</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>11483</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Mực nước tạo lòng</td>
                                            <td>m</td>
                                            <td>2.9</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Lưu lượng thiết kế tại đầu cù lao</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>15000</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Mực nước thiết kế</td>
                                            <td>m</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <th>5</th>
                                            <th>Chiều rộng thượng lưu/hạ lưu</th>
                                            <th>m</th>
                                            <th>880/660</th>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Vận tốc trung bình</td>
                                            <td>m/s</td>
                                            <td>0,4-1,0</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Bán kính cong R</td>
                                            <td>m</td>
                                            <td>4300</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Cao độ nạo nét</td>
                                            <td>m</td>
                                            <td>-17</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao</td>
                                            <td>%</td>
                                            <td>37/63</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Chiều sâu lòng sông ứng với lưu lượng tạo lòng (h<sub>tl</sub>)</td>
                                            <td>m</td>
                                            <td>18.9</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Đường kính hạt trong khu vực nghiên cứu (d<sub>h</sub>)</td>
                                            <td>mm</td>
                                            <td>0.204</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Độ dốc mặt nước (i)</td>
                                            <td></td>
                                            <td>0.000038</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td>Chỉ số ổn định theo chiều dọc tuyến chỉnh trị (n<sub>h</sub>)</td>
                                            <td></td>
                                            <td>0.29</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                            <td>Chỉ số ổn định theo chiều ngang tuyến chỉnh trị (n<sub>n</sub>)</td>
                                            <td></td>
                                            <td>1.07</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {/* Sông Tiền - Thành phố Mỹ Tho */}
                            {feature[fieldName]===8&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th colspan="4">SÔNG TIỀN - THÀNH PHỐ MỸ THO</th>
                                        </tr>
                                        <tr>
                                            <th>STT</th>
                                            <th>Thông số chỉnh trị</th>
                                            <th>Đơn vị</th>
                                            <th>Giá trị</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Lưu lượng tạo lòng</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>4073</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Mực nước tạo lòng</td>
                                            <td>m</td>
                                            <td>2.1</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Lưu lượng thiết kế tại đầu cù lao</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>5300</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Mực nước thiết kế</td>
                                            <td>m</td>
                                            <td>2,1</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Chiều rộng thượng lưu/hạ lưu</td>
                                            <td>m</td>
                                            <td>1785/1568</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Vận tốc trung bình</td>
                                            <td>m/s</td>
                                            <td>0,2-0,6</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Bán kính cong R</td>
                                            <td>m</td>
                                            <td>5328</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Cao độ nạo nét</td>
                                            <td>m</td>
                                            <td>-13</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao Giêng Q<sub>T</sub>/Q<sub>P</sub></td>
                                            <td>%</td>
                                            <td>82/18</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Chiều sâu lòng sông ứng với lưu lượng tạo lòng (h<sub>tl</sub>)</td>
                                            <td>m</td>
                                            <td>12.6</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Đường kính hạt trong khu vực nghiên cứu (d<sub>h</sub>)</td>
                                            <td>mm</td>
                                            <td>0.1</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td>Độ dốc mặt nước (i)</td>
                                            <td></td>
                                            <td>0.000022</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                            <td>Chỉ số ổn định theo chiều dọc tuyến chỉnh trị (n<sub>h</sub>)</td>
                                            <td></td>
                                            <td>0.36</td>
                                        </tr>
                                        <tr>
                                            <td>15</td>
                                            <td>Chỉ số ổn định theo chiều ngang tuyến chỉnh trị (n<sub>n</sub>)</td>
                                            <td></td>
                                            <td>1.61</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {/* Sông Tiền - Cù Lao Tân Mỹ - Thanh Bình */}
                            {feature[fieldName]===10&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th colspan="4">SÔNG TIỀN - CÙ LAO TÂN MỸ - THANH BÌNH</th>
                                        </tr>
                                        <tr>
                                            <th>STT</th>
                                            <th>Thông số chỉnh trị</th>
                                            <th>Đơn vị</th>
                                            <th>Giá trị</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Lưu lượng tạo lòng</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>10125</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Mực nước tạo lòng</td>
                                            <td>m</td>
                                            <td>2.7</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Lưu lượng thiết kế tại đầu cù lao</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>16228</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Mực nước thiết kế</td>
                                            <td>m</td>
                                            <td>2.9</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Chiều rộng thượng lưu/hạ lưu</td>
                                            <td>m</td>
                                            <td>1100/800</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Vận tốc trung bình</td>
                                            <td>m/s</td>
                                            <td>0,3-1,8</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Bán kính cong R</td>
                                            <td>m</td>
                                            <td>4100</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Cao độ nạo nét</td>
                                            <td>m</td>
                                            <td>-22</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao Giêng Q<sub>T</sub>/Q<sub>P</sub></td>
                                            <td>%</td>
                                            <td>94/6</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Tỷ lệ phân lưu nút hợp lưu tại Cầu Cao Lãnh Q<sub>T</sub>/Q<sub>P</sub></td>
                                            <td>%</td>
                                            <td>37/63</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Chiều sâu lòng sông ứng với lưu lượng tạo lòng (h<sub>tl</sub>)</td>
                                            <td>m</td>
                                            <td>18.4</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Đường kính hạt trong khu vực nghiên cứu (d<sub>h</sub>)</td>
                                            <td>mm</td>
                                            <td>0.165</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td>Độ dốc mặt nước (i)</td>
                                            <td></td>
                                            <td>0.000033</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                            <td>Chỉ số ổn định theo chiều dọc tuyến chỉnh trị (n<sub>h</sub>)</td>
                                            <td></td>
                                            <td>0.28</td>
                                        </tr>
                                        <tr>
                                            <td>15</td>
                                            <td>Chỉ số ổn định theo chiều ngang tuyến chỉnh trị (n<sub>n</sub>)</td>
                                            <td></td>
                                            <td>1.11</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {/* Sông Tiền - Tân Châu - Hồng Ngự */}
                            {feature[fieldName]===9&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th colspan="4">SÔNG TIỀN - TÂN CHÂU - HỒNG NGỰ</th>
                                        </tr>
                                        <tr>
                                            <th>STT</th>
                                            <th>Thông số chỉnh trị</th>
                                            <th>Đơn vị</th>
                                            <th>Giá trị</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Lưu lượng tạo lòng</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>22000</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Mực nước tạo lòng</td>
                                            <td>m</td>
                                            <td>3.4</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Lưu lượng thiết kế tại đầu cù lao</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>22703</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Mực nước thiết kế</td>
                                            <td>m</td>
                                            <td>3.7</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Chiều rộng thượng lưu/hạ lưu</td>
                                            <td>m</td>
                                            <td>2000/1100</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Vận tốc trung bình</td>
                                            <td>m/s</td>
                                            <td>0,5-1,0</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Bán kính cong R</td>
                                            <td>m</td>
                                            <td>4300</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Cao độ nạo nét</td>
                                            <td>m</td>
                                            <td>(-12) và (-17)</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao (Q<sup>T</sup>/Q<sup>P</sup>)</td>
                                            <td>%</td>
                                            <td>39/61</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Chiều sâu lòng sông ứng với lưu lượng tạo lòng (h<sub>tl</sub>)</td>
                                            <td>m</td>
                                            <td>22.3</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Đường kính hạt trong khu vực nghiên cứu (d<sub>h</sub>)</td>
                                            <td>mm</td>
                                            <td>0.26</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Độ dốc mặt nước (i)</td>
                                            <td></td>
                                            <td>0.00003</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td>Chỉ số ổn định theo chiều dọc tuyến chỉnh trị (n<sub>h</sub>)</td>
                                            <td></td>
                                            <td>0.39</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                            <td>Chỉ số ổn định theo chiều ngang tuyến chỉnh trị (n<sub>n</sub>)</td>
                                            <td></td>
                                            <td>1.68</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {/* Sông Tiền - Châu Ma */}
                            {feature[fieldName]===7&&(
                                <table className={css('table')}>
                                    <thead>
                                        <tr>
                                            <th colspan="4">SÔNG TIỀN - CHÂU MA</th>
                                        </tr>
                                        <tr>
                                            <th>STT</th>
                                            <th>Thông số chỉnh trị</th>
                                            <th>Đơn vị</th>
                                            <th>Giá trị</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Lưu lượng tạo lòng</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>18375</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Mực nước tạo lòng</td>
                                            <td>m</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Lưu lượng thiết kế tại đầu cù lao</td>
                                            <td>m<sup>3</sup>/s</td>
                                            <td>22675</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Mực nước thiết kế</td>
                                            <td>m</td>
                                            <td>2.38</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Chiều rộng thượng lưu/hạ lưu</td>
                                            <td>m</td>
                                            <td>2800/1400</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Vận tốc trung bình</td>
                                            <td>m/s</td>
                                            <td>0,4-0,7</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Bán kính cong R</td>
                                            <td>m</td>
                                            <td>2600</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Cao độ nạo nét (Long Khánh)</td>
                                            <td>m</td>
                                            <td>(-12) và (-17)</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Cao độ nạo nét (nhánh trái)</td>
                                            <td>m</td>
                                            <td>(-17) và (-19)</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao (Q<sup>T</sup>/Q<sup>P</sup>) - MAX</td>
                                            <td>%</td>
                                            <td>71/34</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao - Mùa lũ</td>
                                            <td>%</td>
                                            <td>68/32</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Tỷ lệ phân lưu nút đầu cù lao - Mùa kiệt</td>
                                            <td>%</td>
                                            <td>67/33</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Chiều sâu lòng sông ứng với lưu lượng tạo lòng (h<sub>tl</sub>)</td>
                                            <td>m</td>
                                            <td>25</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Đường kính hạt trong khu vực nghiên cứu (d<sub>h</sub>)</td>
                                            <td>mm</td>
                                            <td>0.28</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Độ dốc mặt nước (i)</td>
                                            <td></td>
                                            <td>0.00004</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td>Chỉ số ổn định theo chiều dọc tuyến chỉnh trị (n<sub>h</sub>)</td>
                                            <td></td>
                                            <td>0.28</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                            <td>Chỉ số ổn định theo chiều ngang tuyến chỉnh trị (n<sub>n</sub>)</td>
                                            <td></td>
                                            <td>2.73</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    ))
                )}
                {activeTab === 'hinhanh' && (
                    <div className={css("tab-pane")}>
                        <ImageSlider urlArr={image}/>
                    </div>
                )}
                {activeTab === 'video' && (
                    <div className={css("tab-pane")}>
                        <VideoPlayer
                            className={css("video")}
                            url={video}
                        />
                    </div>
                )}
            </div>
        </div>);
}

export default TabsMap;
