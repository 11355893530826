import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import className from 'classnames/bind';
import styles from './Tree.module.scss';
import File from '~/Api/File';
import PopupMenu from '~/Components/Controls/PopupMenu';
import Toast from '~/Components/Controls/Toast';
const css = className.bind(styles);

function Tree({dataTree, onDataChange, listIdFile, cross, isLogin, radioV}) {
  const [isCross, setIsCross] = useState(false);
  const [idChecked, setIdChecked] = useState([]);
  const [idFileChecked, setIdFileChecked] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [openFolder, setOpenFolder] = useState({});
  const [checkedListFile, setCheckedListFile] = useState({});
  const [checkedListFolderFullChild, SetCheckedListFolderFullChild] = useState({});
  const [listFile, setListFile] = useState([]);
  const [position, setPosition] = useState(null);
  const [valueName, setValueName] = useState('');
  const inputRef = useRef(null);
  const [message, setMessage] = useState('');
  const [typeToast, setTypeToast] = useState('success');
  const [showToast, setShowToast] = useState(false);
  const [openCrossFile, setOpenCrossFile] = useState(false);
  const [openBDLD, setOpenBDLD] = useState(false);
  const [openDB, setOpenDB] = useState(false);
  const [openV1, setOpenV1] = useState(false);
  const [openV2, setOpenV2] = useState(false);
  const [openV3, setOpenV3] = useState(false);
  const [openV4, setOpenV4] = useState(false);
  const [radioValue, setRadioValue] = useState('');

  const handleClickValue = () => {
    setOpenBDLD(!openBDLD);
    setRadioValue(!openBDLD?'2':'');
    radioV(!openBDLD?'2':'');
  };

  const handleRadioValue = (event) => {
    var value = event.target.value;
    setOpenBDLD(true);
    setRadioValue(value);
    radioV(value);
  };

  const handleClickValueDB = () => {
    setOpenDB(!openDB);
    setOpenBDLD(false);
    setOpenV1(false);
    setOpenV2(false);
    setOpenV3(false);
    setOpenV4(false);
    setRadioValue(!openDB?'12':'');
    radioV(!openDB?'12':'');
  };

  const handleRadioValueDB = (event) => {
    var value = event.target.value;
    setOpenDB(true);
    setOpenBDLD(false);
    setOpenV1(false);
    setOpenV2(false);
    setOpenV3(false);
    setOpenV4(false);
    setRadioValue(value);
    radioV(value);
  };

  const handleClickValueV1 = () => {
    setOpenBDLD(false);
    setOpenV1(!openV1);
    setOpenV2(false);
    setOpenV3(false);
    setOpenV4(false);
    setRadioValue(!openV1?'13':'');
    radioV(!openV1?'13':'');
  };
  
  const handleRadioValueV1 = (event) => {
    var value = event.target.value;
    setOpenV1(true);
    setOpenV2(false);
    setOpenV3(false);
    setOpenV4(false);
    setOpenDB(true);
    setOpenBDLD(false);
    setRadioValue(value);
    radioV(value);
  };

  const handleClickValueV2 = () => {
    setOpenBDLD(false);
    setOpenV1(false);
    setOpenV2(!openV2);
    setOpenV3(false);
    setOpenV4(false);
    setRadioValue(!openV2?'16':'');
    radioV(!openV2?'16':'');
  };

  const handleRadioValueV2 = (event) => {
    var value = event.target.value;
    setOpenDB(true);
    setOpenBDLD(false);
    setOpenV1(false);
    setOpenV2(true);
    setOpenV3(false);
    setOpenV4(false);
    setRadioValue(value);
    radioV(value);
  };

  const handleClickValueV3 = () => {
    setOpenBDLD(false);
    setOpenV1(false);
    setOpenV2(false);
    setOpenV3(!openV3);
    setOpenV4(false);
    setRadioValue(!openV3?'19':'');
    radioV(!openV3?'19':'');
  };

  const handleRadioValueV3 = (event) => {
    var value = event.target.value;
    setOpenDB(true);
    setOpenBDLD(false);
    setOpenV1(false);
    setOpenV2(false);
    setOpenV3(true);
    setOpenV4(false);
    setRadioValue(value);
    radioV(value);
  };

  const handleClickValueV4 = () => {
    setOpenBDLD(false);
    setOpenV1(false);
    setOpenV2(false);
    setOpenV3(false);
    setOpenV4(!openV4);
    setRadioValue(!openV4?'22':'');
    radioV(!openV4?'22':'');
  };

  const handleRadioValueV4 = (event) => {
    var value = event.target.value;
    setOpenDB(true);
    setOpenBDLD(false);
    setRadioValue(value);
    setOpenV1(false);
    setOpenV2(false);
    setOpenV3(false);
    setOpenV4(true);
    radioV(value);
  };

  const handleOpenBDLD = () => {
    setOpenBDLD(!openBDLD);
  };

  const handleOpenDB = () => {
    setOpenDB(!openDB);
  };

  const handleOpenV1 = () => {
    setOpenV1(!openV1);
  };

  const handleOpenV2 = () => {
    setOpenV2(!openV2);
  };

  const handleOpenV3 = () => {
    setOpenV3(!openV3);
  };

  const handleOpenV4 = () => {
    setOpenV4(!openV4);
  };

  const handleOpenCrossFile = () => {
    setOpenCrossFile(!openCrossFile);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };
  
  let transformedData;
  if(dataTree){
    transformedData = buildFolderStructure(dataTree);
  }

  const handleOpenCross = (e) => {
    const isChecked = e.target.checked;
    setIsCross(isChecked);
    cross(isChecked);
    handleOpenCrossFile();
  }

  useEffect(() => {
    const file = new File();
    file.getFiles()
    .then(response => {
      if(response.status === 200){
        setListFile(response.data);
      } else {
        setMessage(response.detail);
        setTypeToast('dangerous');
        setShowToast(true);
      }
    });
  }, [dataTree]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideInputClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideInputClick);
    };
  }, []);
  
  function buildFolderStructure(data) {
    const folderMap = {};
    const folderTree = [];   
    
    data.forEach((folder) => {
      folder.folderChild = [];
      folderMap[folder.id] = folder;
    });
    
    data.forEach((folder) => {
      const parent = folderMap[folder.rootId];
      if (parent) {
        parent.folderChild.push(folder);
      } else {
        folderTree.push(folder);
      }
    });
    return folderTree;
  };

  const handleCheckboxChange = (event, itemId, folderData) => {
    const isChecked = event.target.checked;
    setPosition(null);
    checkedChild(isChecked?0:1, itemId); 
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = { ...prevCheckedItems };
      if (isChecked) {
        updatedCheckedItems[itemId] = true;
        funcsCheckFolder(itemId);
      } else {
        let listIdChecked = [];
        delete updatedCheckedItems[itemId];
        setIdChecked((prevIdChecked) => {
          const newArray = prevIdChecked.filter((x) => x !== itemId);
          onDataChange(newArray);
          listIdChecked = newArray;
          return newArray;
        });
        const resListFiles = listFile.filter((x) => x.folderId === itemId);
        resListFiles.map((res) => {
          setCheckedListFile((prevCheckedListFile) => {
            const updatedCheckedListFile = { ...prevCheckedListFile };
            if (!isChecked) {
              delete updatedCheckedListFile[res.id];
              setIdFileChecked((prevIdFileChecked) => {
                const newArray = prevIdFileChecked.filter((x) => x !== res.id);
                listIdFile(newArray);
                checkEveryChildOnFolder(1, itemId, listIdChecked, newArray);
                return newArray;
              });
            }
            return updatedCheckedListFile;
          });
        })
      }
      return updatedCheckedItems;
    });
    setOpenFolder((prevCheckedItems) => {
      const updatedCheckedItems = { ...prevCheckedItems };
      if(isChecked){
        updatedCheckedItems[itemId] = true;
      } else {
        // delete updatedCheckedItems[itemId];
      }
      return updatedCheckedItems;
    });
  };

  const funcsCheckFolder = (FolderId) => {
    setIdChecked((prevIdChecked) => {
      const newArray = [...prevIdChecked, FolderId];
      onDataChange(newArray);
      const resListFiles = listFile.filter((x) => x.folderId===FolderId);
      resListFiles.map((res) => {
        setCheckedListFile((prevCheckedListFile) => {
          const updatedCheckedListFile = { ...prevCheckedListFile };
          updatedCheckedListFile[res.id] = true;
          setIdFileChecked((prevIdFileChecked) => {
            const newArray1 = [...prevIdFileChecked, res.id];
            listIdFile(newArray1);
            checkEveryChildOnFolder(0, FolderId, newArray, newArray1);
            checkParentFolder(FolderId);
            return newArray1;
          });
          return updatedCheckedListFile;
        });
      });
      return newArray;
    });
  }

  const checkParentFolder = (FolderId) => {
    const parentFolder = dataTree.filter((x) => {
      if(x.id === FolderId){
        const folder = dataTree.filter((y) => y.id === x.rootId);
        return folder[0];
      }
    });
    parentFolder.map((x) => {
      setCheckedItems((prevCheckedItems) => {
        const updatedCheckedItems = { ...prevCheckedItems };
        updatedCheckedItems[x.rootId] = true;
        funcsCheckFolder(x.rootId);
        return updatedCheckedItems;
      });
      setOpenFolder((prevCheckedItems) => {
        const updatedCheckedItems = { ...prevCheckedItems };
        updatedCheckedItems[x.rootId] = true;
        return updatedCheckedItems;
      });
    });
  }


  const openFilesOnFolder = (FolderId) => {
    if(openFolder[FolderId]){
      setOpenFolder((prevCheckedItems) => {
        const updatedCheckedItems = { ...prevCheckedItems };
        delete updatedCheckedItems[FolderId];
        return updatedCheckedItems;
      });
    } else {
      setOpenFolder((prevCheckedItems) => {
        const updatedCheckedItems = { ...prevCheckedItems };
        updatedCheckedItems[FolderId] = true;
        return updatedCheckedItems;
      });
    }
  }

  const getAllChildItems = (item) => {
    let childItems = [];
    if (item.folderChild && item.folderChild.length > 0) {
      childItems = item.folderChild.reduce((acc, childItem) => {
        acc.push(childItem, ...getAllChildItems(childItem));
        return acc;
      }, []);
    }
    return childItems;
  };

  const renderTreeItem = (item, level = 0, isExpanded = true ) => {
    const hasChildren = item.folderChild && item.folderChild.length > 0;
    return (
      <div key={item.id} style={{paddingLeft: `${(level+2)/10}rem`}}>
        <div style={{fontWeight: '600', cursor: 'Pointer'}}>
          <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={openFolder[item.id]?faChevronDown:faChevronRight} onClick={()=> openFilesOnFolder(item.id)}/>
          {' '}
          <input
            id=''
            className={css(`regular-checkbox-folder${checkedListFolderFullChild!=null&&Object.keys(checkedListFolderFullChild).length>0?checkedListFolderFullChild[item.id]!=null?'-1':'':''}`)}
            type="checkbox"
            checked={checkedItems[item.id] || false}
            onChange={(event) => handleCheckboxChange(event, item.id, dataTree)}
          />
          <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} alt='folder'/>
          {item.foldersName}
        </div>
        {openFolder[item.id]&&(renderFileByFolder(item.id, level))}
        {hasChildren && (isExpanded || checkedItems[item.id]) && (
          <div>
            {item.folderChild.map((childItem) =>
              renderTreeItem(childItem, (level+10), isExpanded)
            )}
          </div>
        )}
      </div>
    );
  };

  const handleCheckboxChangeFile = (event, itemId, FolderId) =>{
    const isChecked = event.target.checked;
    setPosition(null);
    setCheckedListFile((prevCheckedListFile) => {
      const updatedCheckedListFile = { ...prevCheckedListFile };
      if (isChecked) {
        updatedCheckedListFile[itemId] = true;
        checkParentFolder(FolderId);
        setIdFileChecked((prevIdFileChecked) => {
          const newArray = [...prevIdFileChecked, itemId];
          listIdFile(newArray);
          const resListFiles = listFile.filter((x) => x.folderId===FolderId);
          const hasCommonElement = resListFiles.some((obj1) => {
            return newArray.includes(obj1.id);
          });
          if (hasCommonElement) {
            setCheckedItems((prevCheckedItems) => {
                const updatedCheckedItems = { ...prevCheckedItems };
                updatedCheckedItems[FolderId] = true;
                setIdChecked((prevIdChecked) => {
                  const newArray1 = [...prevIdChecked, FolderId];
                  checkEveryChildOnFolder(0, FolderId, newArray1, newArray);
                  onDataChange(newArray1);
                  return newArray1;
                });
              return updatedCheckedItems;
            })
            setOpenFolder((prevCheckedItems) => {
              const updatedCheckedItems = { ...prevCheckedItems };
              updatedCheckedItems[FolderId] = true;
              return updatedCheckedItems;
            });
          }
          return newArray;
        });
      } else {
        delete updatedCheckedListFile[itemId];
        setIdFileChecked((prevIdFileChecked) => {
          const newArray = prevIdFileChecked.filter((x) => x !== itemId);
          listIdFile(newArray);
          const resListFiles = listFile.filter((x) => x.folderId===FolderId);
          const hasCommonElement = resListFiles.some((obj1) => {
            return newArray.includes(obj1.id);
          });
          setIdChecked((prevIdChecked) => {
            checkEveryChildOnFolder(1, FolderId, prevIdChecked, newArray);
            const newArray1 = prevIdChecked.filter((x) => x !== FolderId);
            const folders = dataTree.filter((x) => x.rootId === FolderId);
            const isUncheckedFolderChill = folders.some((obj) => {
              return newArray1.includes(obj.id);
            })
            if(!hasCommonElement&&!isUncheckedFolderChill){
              onDataChange(newArray1);
              setCheckedItems((prevCheckedItems) => {
                const updatedCheckedItems = { ...prevCheckedItems };
                delete updatedCheckedItems[FolderId];   
                return updatedCheckedItems;
              });
              setOpenFolder((prevCheckedItems) => {
                const updatedCheckedItems = { ...prevCheckedItems };
                delete updatedCheckedItems[FolderId];
                return updatedCheckedItems;
              });
              return newArray1;
            } else {
              return prevIdChecked;
            }
          });
          return newArray;
        });  
      }
      return updatedCheckedListFile;
    })
  };

  const [isOpenInputFile, setIsOpenInputFile] = useState(false);
  const [isChangeValueInput, setIsChangeValueInput] = useState(false);
  const [idInput, setIdInput] = useState(null);

  const handleGetValue = (event) => {
    if(event){
      setIsChangeValueInput(true);
    };
    setValueName(event.target.value);
  };

  const handleEnterInput = (event) => {
    if(event.key === 'Enter'){
      funcRename();
    }
  };

  const checkedChild = (type, FolderId) => {
    if(type===0) {
      let listFolder = dataTree.filter((x) => x.rootId === FolderId);
      let arrayListFolders = [];
      let arrayListFiles = [];
      listFolder.map((res) => {
        setOpenFolder((prevCheckedItems) => {
          const updatedCheckedItems = { ...prevCheckedItems };
          updatedCheckedItems[res.id] = true;
          return updatedCheckedItems;
        });
        setCheckedItems((prevCheckedItems) => {
          const updatedCheckedItems = { ...prevCheckedItems };
          updatedCheckedItems[res.id] = true;
          setIdChecked((prevIdChecked) => {
            const newArray = [...prevIdChecked, res.id];
            arrayListFolders = newArray;
            const resListFiles = listFile.filter((x) => x.folderId===res.id);
            resListFiles.map((res1) => {
              setCheckedListFile((prevCheckedListFile) => {
                const updatedCheckedListFile = { ...prevCheckedListFile };
                updatedCheckedListFile[res1.id] = true;
                setIdFileChecked((prevIdFileChecked) => {
                  const newArray1 = [...prevIdFileChecked, res1.id];
                  arrayListFiles = newArray1;
                  checkEveryChildOnFolder(0, res.id, newArray, newArray1);
                  return newArray1;
                });
                return updatedCheckedListFile;
              });
            });
            return newArray;
          }) 
          return updatedCheckedItems;
        })
      });
      onDataChange(arrayListFolders);
      listIdFile(arrayListFiles);
    } else {
      uncheckedAll(FolderId);
    }
  }

  const uncheckedAll = (FolderId) => {
    let arrayListFolders = [];
    let arrayListFiles = [];
    let listFolder = dataTree.filter((x) => x.rootId === FolderId);
    if(listFolder.length>0){
      listFolder.map((res) => {
        setOpenFolder((prevCheckedItems) => {
          const updatedCheckedItems = { ...prevCheckedItems };
          delete updatedCheckedItems[FolderId];
          return updatedCheckedItems;
        });
        setCheckedItems((prevCheckedItems) => {
          const updatedCheckedItems = { ...prevCheckedItems };
          delete updatedCheckedItems[res.id];
          setIdChecked((prevIdChecked) => {
            const newArray = prevIdChecked.filter((x) => x !== res.id);
            arrayListFolders = newArray;
            const resListFiles = listFile.filter((x) => x.folderId===res.id);
            resListFiles.map((res1) => {
              setCheckedListFile((prevCheckedListFile) => {
                const updatedCheckedListFile = { ...prevCheckedListFile };
                delete updatedCheckedListFile[res1.id];
                setIdFileChecked((prevIdFileChecked) => {
                  const newArray1 = prevIdFileChecked.filter((x) => x !== res1.id);
                  arrayListFiles = newArray1;
                  checkEveryChildOnFolder(0, FolderId, newArray, newArray1);
                  uncheckedAll(res.id)
                  return newArray1;
                });
                return updatedCheckedListFile;
              });
            });
            return newArray;
          }) 
          return updatedCheckedItems;
        });
      });
    }
    onDataChange(arrayListFolders);
    listIdFile(arrayListFiles);
  }

  const checkEveryChildOnFolder = (type, FolderId, listIdChecked, listIdFileChecked) => {
    if(type===0) {
      let checkedFile = false;
      let checkedFolder = false;
      if(listIdChecked&&listIdFileChecked){
        const resListFiles = listFile.filter((x) => x.folderId===FolderId);
        checkedFile = resListFiles.every(item => listIdFileChecked.includes(item.id));
        const resListFolder = dataTree.filter((x) => x.rootId===FolderId);
        if(resListFolder.length>0){
          checkedFolder = resListFolder.every(item => listIdChecked.includes(item.id));
        } else {
          checkedFolder = true;
        }
        if(checkedFile&&checkedFolder){
          SetCheckedListFolderFullChild((prevListFolderFullChild) => {
            const updatedCheckedListFile = { ...prevListFolderFullChild };
            updatedCheckedListFile[FolderId] = true;
            return updatedCheckedListFile;
          })
        }
      }
    } else {
      let root = dataTree.filter((x) => x.id===FolderId);
      SetCheckedListFolderFullChild((prevListFolderFullChild) => {
        const updatedCheckedListFile = { ...prevListFolderFullChild };
        delete updatedCheckedListFile[FolderId];
        return updatedCheckedListFile;  
      });
      if(root[0].rootId!=0){
        SetCheckedListFolderFullChild((prevListFolderFullChild) => {
          const updatedCheckedListFile = { ...prevListFolderFullChild };
          delete updatedCheckedListFile[root[0].rootId];
          return updatedCheckedListFile;  
        });
      } 
    }
  }

  const renderFileByFolder = (FolderId, level) =>{
    return (listFile && 
      listFile.map((res, idx) => 
        res.folderId === FolderId && 
          (<>
            <div key={res.id} className={css('item-file')} style={{paddingLeft: `${(level+20)/10}rem`}}>
              <label className={css('label-container-file')} onContextMenu={(e)=>handleMouseDown(e, res.id)}>
                <input
                  id='checkbox-file'
                  className={css('regular-checkbox-file')}
                  type="checkbox"
                  checked={checkedListFile[res.id]||false}
                  onChange={(event) => handleCheckboxChangeFile(event, res.id, FolderId)}
                  styles={styles}
                />
                <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='file'/>
                {isOpenInputFile && idInput===res.id? (
                    <input ref={inputRef} type='input' className={css('input-file')} idFile={res.id} onChange={handleGetValue} value={!isChangeValueInput&&res.nameFile?res.nameFile:valueName} onKeyPress={(e) => handleEnterInput(e)}/>
                ):(<p className={css('label-file')} style={{cursor: 'Pointer'}}>{res.nameFile?res.nameFile:'Không xác định'}</p>)}
              </label>
            </div>
            <PopupMenu position={position} rename={(e) => handleOpenRename(e)} deleteFile={handleDelete}/>
          </>) 
      ));
  };

  const handleOpenRename = (data) => {
    setIsOpenInputFile(data.open);
    setIdInput(data.id);
  };

  const handleDelete = (data) => {
    const file = new File();
    file.deleteFile(data.id)
    .then(response => {
      if(response.status === 200){
        file.getFiles()
        .then(response1 => {
          if(response1.status === 200){
            setMessage('Xóa thành công!');
            setTypeToast('success');
            setShowToast(true);
            setListFile(response1.data);
          } else {
            setMessage(response1.detail);
            setTypeToast('dangerous');
            setShowToast(true);
          }
        })
      } else {
        setMessage(response.detail);
        setTypeToast('dangerous');
        setShowToast(true);
      }
    })
  };

  const handleMouseDown = (event, id) => {
    if(isLogin){
      const object = { id: id, x: event.clientX, y: event.clientY };
      setPosition(object);
    }
  };

  const handleOutsideInputClick = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      funcRename();
    }
  };

  const funcRename = () => {
    const formData = new FormData();
      formData.append('Id', inputRef.current.getAttribute('idfile'));
      formData.append('Name', inputRef.current.value);
      const file = new File();
      file.updateRenameFile(formData)
      .then(response => {
        if(response.status === 200){
          setMessage('Đổi tên thành công!');
          setTypeToast('success');
          setShowToast(true);
          file.getFiles()
          .then(response1 => {
            if(response1.status === 200){
              setListFile(response1.data);
            } else {
              setMessage(response1.detail);
              setTypeToast('dangerous');
              setShowToast(true);
            }
          })
        } else {
          setMessage(response.detail);
          setTypeToast('dangerous');
          setShowToast(true);
        }
        setValueName('');
      })       
      setIsOpenInputFile(false);
      setIsChangeValueInput(false); 
  }

  return (
    <>
      <Toast
        message={message}
        onClose={handleCloseToast}
        showToast={showToast}
        type={typeToast}
        duration={2000}
      />
      <div className={css('map-tree')}>
        {transformedData.map((item) => renderTreeItem(item))}
        <div style={{paddingLeft: `${(0+2)/10}rem`}}>
          <div style={{fontWeight: '600', cursor: 'Pointer'}}>
            <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={isCross?faChevronDown:faChevronRight} onClick={handleOpenCrossFile}/>
            {' '}
            <input
              id=''
              className={css(`regular-checkbox-folder${isCross?'-1':''}`)}
              type="checkbox"
              checked={isCross}
              onChange={(event) => handleOpenCross(event)}
            />
            <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} alt='folder'/>
            Mặt cắt
          </div>
          {openCrossFile && (
            <div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    id='checkbox-file'
                    className={css('regular-checkbox-file')}
                    type="checkbox"
                    checked={isCross}
                    onChange={(event) => handleOpenCross(event)}
                    styles={styles}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='file'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Mặt cắt</p>
                </label>
              </div>
            </div>
          )}
        </div>
        <div style={{paddingLeft: `${(0+2)/10}rem`}}>
          <div style={{fontWeight: '600', cursor: 'Pointer'}}>
            <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={openBDLD?faChevronDown:faChevronRight} onClick={handleOpenBDLD}/>
            {' '}
            <input
              id=''
              className={css(`regular-checkbox-folder-1`)}
              type="checkbox"
              checked={openBDLD&&radioValue!==''}
              onChange={(event) => handleClickValue(event)}
            />
            <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} alt='folder'/>
            Biến động lòng dẫn
          </div>
          {openBDLD && (
            <div>
              {/* <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    className={css('regular-checkbox-file')}
                    value='1'
                    type="radio"
                    checked={radioValue === '1'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Biến động lòng dẫn sau 1 năm'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Biến động lòng dẫn sau 1 năm</p>
                </label>
              </div> */}
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='2'
                    type="radio"
                    checked={radioValue === '2'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Dự báo 4 năm</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='3'
                    type="radio"
                    checked={radioValue === '3'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo năm 2030'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Dự báo năm 2030</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='4'
                    type="radio"
                    checked={radioValue === '4'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo biến đổi khí hậu'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Kịch bản biến đổi khí hậu</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='5'
                    type="radio"
                    checked={radioValue === '5'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo biến đổi tự nhiên'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Kịch bản biến đổi thượng nguồn</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='6'
                    type="radio"
                    checked={radioValue === '6'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo điển hình'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Kịch bản điển hình</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='7'
                    type="radio"
                    checked={radioValue === '7'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo hạn mặn'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Kịch bản hạn mặn</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='8'
                    type="radio"
                    checked={radioValue === '8'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo KTC'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Kịch bản KTC</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='9'
                    type="radio"
                    checked={radioValue === '9'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo lũ 1'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Kịch bản lũ 1</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='10'
                    type="radio"
                    checked={radioValue === '10'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo tạo lòng'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Kịch bản tạo lòng</p>
                </label>
              </div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    // className={css('regular-checkbox-file')}
                    value='11'
                    type="radio"
                    checked={radioValue === '11'}
                    onChange={(event) => handleRadioValue(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo tổ hợp' />
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Kịch bản tổ hợp</p>
                </label>
              </div>
            </div>
          )}
        </div>
        <div style={{paddingLeft: `${(0+2)/10}rem`}}>
          <div style={{fontWeight: '600', cursor: 'Pointer'}}>
            <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={openDB?faChevronDown:faChevronRight} onClick={handleOpenDB}/>
            {' '}
            <input
              id=''
              className={css(`regular-checkbox-folder-1`)}
              type="checkbox"
              checked={openDB&&radioValue!==''}
              onChange={(event) => handleClickValueDB(event)}
            />
            <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} alt='folder'/>
            Diễn biến đường bờ
          </div>
          {openDB && (
            <div>
              <div className={css('item-file')} style={{paddingLeft: `${(20)/10}rem`}}>
                <label className={css('label-container-file')}>
                  <input
                    value='12'
                    type="radio"
                    checked={radioValue === '12'}
                    onChange={(event) => handleRadioValueDB(event)}
                  />
                  <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                  <p className={css('label-file')} style={{cursor: 'Pointer'}}>Tổng diễn biến từ năm 1998 - 2022</p>
                </label>
              </div>
              <div style={{paddingLeft: `${(20+2)/10}rem`}}>
                <div style={{fontWeight: '600'}}>
                  <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={openV1?faChevronDown:faChevronRight} onClick={handleOpenV1}/>
                  {' '}
                  <input
                    id=''
                    className={css(`regular-checkbox-folder-1`)}
                    type="checkbox"
                    checked={openV1&&radioValue!==''}
                    onChange={(event) => handleClickValueV1(event)}
                  />
                  <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} alt='folder'/>
                  Vùng 1
                </div>
                {openV1 && (
                  <div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='13'
                          type="radio"
                          checked={radioValue === '13'}
                          onChange={(event) => handleRadioValueV1(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 1 năm 1998-2009</p>
                      </label>
                    </div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='14'
                          type="radio"
                          checked={radioValue === '14'}
                          onChange={(event) => handleRadioValueV1(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 1 năm 2009-2020</p>
                      </label>
                    </div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='15'
                          type="radio"
                          checked={radioValue === '15'}
                          onChange={(event) => handleRadioValueV1(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 1 năm 2020-2022</p>
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div style={{paddingLeft: `${(20+2)/10}rem`}}>
                <div style={{fontWeight: '600'}}>
                  <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={openV2?faChevronDown:faChevronRight} onClick={handleOpenV2}/>
                  {' '}
                  <input
                    id=''
                    className={css(`regular-checkbox-folder-1`)}
                    type="checkbox"
                    checked={openV2&&radioValue!==''}
                    onChange={(event) => handleClickValueV2(event)}
                  />
                  <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} alt='folder'/>
                  Vùng 2
                </div>
                {openV2 && (
                  <div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='16'
                          type="radio"
                          checked={radioValue === '16'}
                          onChange={(event) => handleRadioValueV2(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 2 năm 1998-2009</p>
                      </label>
                    </div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='17'
                          type="radio"
                          checked={radioValue === '17'}
                          onChange={(event) => handleRadioValueV2(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 2 năm 2009-2020</p>
                      </label>
                    </div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='18'
                          type="radio"
                          checked={radioValue === '18'}
                          onChange={(event) => handleRadioValueV2(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 2 năm 2020-2022</p>
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div style={{paddingLeft: `${(20+2)/10}rem`}}>
                <div style={{fontWeight: '600'}}>
                  <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={openV3?faChevronDown:faChevronRight} onClick={handleOpenV3}/>
                  {' '}
                  <input
                    id=''
                    className={css(`regular-checkbox-folder-1`)}
                    type="checkbox"
                    checked={openV3&&radioValue!==''}
                    onChange={(event) => handleClickValueV3(event)}
                  />
                  <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} alt='folder'/>
                  Vùng 3
                </div>
                {openV3 && (
                  <div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='19'
                          type="radio"
                          checked={radioValue === '19'}
                          onChange={(event) => handleRadioValueV3(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 3 năm 1998-2009</p>
                      </label>
                    </div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='20'
                          type="radio"
                          checked={radioValue === '20'}
                          onChange={(event) => handleRadioValueV3(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 3 năm 2009-2020</p>
                      </label>
                    </div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='21'
                          type="radio"
                          checked={radioValue === '21'}
                          onChange={(event) => handleRadioValueV3(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 3 năm 2020-2022</p>
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div style={{paddingLeft: `${(20+2)/10}rem`}}>
                <div style={{fontWeight: '600'}}>
                  <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={openV4?faChevronDown:faChevronRight} onClick={handleOpenV4}/>
                  {' '}
                  <input
                    id=''
                    className={css(`regular-checkbox-folder-1`)}
                    type="checkbox"
                    checked={openV4&&radioValue!==''}
                    onChange={(event) => handleClickValueV4(event)}
                  />
                  <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} alt='folder'/>
                  Vùng 4
                </div>
                {openV4 && (
                  <div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='22'
                          type="radio"
                          checked={radioValue === '22'}
                          onChange={(event) => handleRadioValueV4(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 4 năm 1990-2009</p>
                      </label>
                    </div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='23'
                          type="radio"
                          checked={radioValue === '23'}
                          onChange={(event) => handleRadioValueV4(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 4 năm 2009-2020</p>
                      </label>
                    </div>
                    <div className={css('item-file')} style={{paddingLeft: `${(40)/10}rem`}}>
                      <label className={css('label-container-file')}>
                        <input
                          value='24'
                          type="radio"
                          checked={radioValue === '24'}
                          onChange={(event) => handleRadioValueV4(event)}
                        />
                        <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} alt='Dự báo 4 năm'/>
                        <p className={css('label-file')} style={{cursor: 'Pointer'}}>Vùng 4 năm 2020-2022</p>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      
    </>  
  );
};

export default Tree;