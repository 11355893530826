import { useState, useEffect, useRef } from 'react';
import styles from './FormLogin.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import Authen from '~/Api/Authen';
import User from '~/Api/User';
import Role from '~/Api/Role';
import Toast from '~/Components/Controls/Toast';

const css = className.bind(styles);

const FormLogin = ({isOpenRegister, isLogin, userId}) =>{
    const [userName, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    
    const loginAuthen = () => {
        const formData = new FormData();
        formData.append('TaiKhoan', userName);
        formData.append('Password', password);

        const authen = new Authen();
        authen.login(formData)
        .then((response) => {
            if(response.status === 200) {
                localStorage.setItem('_token', response.data.token);
                const user = new User();
                user.getNguoiDungByUserName(userName)
                .then((response) => {
                    if(response.status === 200) {
                        userId(response.data.id);
                        isLogin(true);
                        const role = new Role();
                        role.getConcurrencyStampByUserId(response.data.id)
                        .then(response1 => {
                            localStorage.setItem('_ConcurrencyStamp', response1.data.concurrencyStamp);
                        });
                        localStorage.setItem('_Id', response.data.id);
                        localStorage.setItem('_userName', userName);
                        setShowToast(true);
                        setMessage('Đăng nhập.');
                        setTypeToast('success');
                    }
                });
            } else {
                setShowToast(true);
                setMessage(response.detail);
                setTypeToast('dangerous');
            }
        });
    };

    const handleUserName = (event) => {
        setUsername(event.target.value);
    };

    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleRegister = () => {
        isOpenRegister(true);
    };

    return (<>
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        <div className={css('form-background')}>
            <div className={css('title-authen')}>
                <p>ĐĂNG NHẬP</p>
            </div>
            <div className={css('group-authen')}>
                <div className={css('group-account')}>
                    <FontAwesomeIcon className={css('icon')} icon={faUser}/>
                    <input type='type' className={css('input')} placeholder='Tài khoản' autoComplete="off" autoCorrect="off" value={userName} onChange={handleUserName}/>
                </div>
                <div className={css('group-password')}>
                    <FontAwesomeIcon className={css('icon')} icon={faLock}/>
                    <input type='password' className={css('input')} placeholder='Mật khẩu' autoComplete="off" autoCorrect="off" value={password} onChange={handlePassword}/>
                </div>
            </div>
            <label className={css('group-save-password')}>
                <input
                    className={css('input-save-password')}
                    type="checkbox"
                />
                <p className={css('title-save-password')}>Lưu mật khẩu</p>
            </label>
            <div className={css('group-btn')}>
                <button className={css('btn-sign')} onClick={() => loginAuthen()}>
                    Đăng nhập
                </button>
                <div className={css('hr')}></div>
                <p className={css('register')} onClick={handleRegister}>Chưa có tài khoản?</p>
            </div>
        </div>
    </>);
}

export default FormLogin;
